import React from 'react'
import Slider from 'react-slick'
import DynamicImage from './DynamicImage'

const ImageSlider = ({ gallery }) => {
  const settings = {
    dots: true,
    className: "image-slider overflow-hidden",
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendArrows: true,
    slide: "span",
    color: "red",
  }
  return (
    <Slider {...settings} className="image-slider overflow-hidden">
      {gallery.map((image, i) => <DynamicImage img={image.image} key={i} />)}
    </Slider>
  )
}

export default ImageSlider
