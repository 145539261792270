import React from 'react'
import { useDispatch } from 'react-redux'
import { addToCart } from '../state/cartSlice'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Header from '../components/Header'
import Footer from '../components/Footer'
import ImageSlider from '../components/ImageSlider'

const Book = ({ data }) => {
  const book = data.markdownRemark.frontmatter
  const dispatch = useDispatch()
  return (
    <div className="book-container">
      <div>
        <Header />
        <section className="container">
          <div className="book-info">
            <section className="book-section">
              <div className="book-img">
                {<ImageSlider gallery={book.gallery} />}
              </div>
              <div className="book-desc">
                <div className="book-properties">
                  <div className="book-title-box">
                    <span>{book.title}</span>
                  </div>
                  <ul>
                    {book.author && (
                      <li>
                        Автор: <span className="context">{book.author}</span>
                      </li>
                    )}
                    {book.publisher && (
                      <li>
                        Видавництво:{" "}
                        <span className="context">{book.publisher}</span>
                      </li>
                    )}
                    {book.language && (
                      <li>
                        Мова:{" "}
                        <span className="context">{book.language}</span>
                      </li>
                    )}
                    {book.release && (
                      <li>
                        Рік видання:{" "}
                        <span className="context">{book.release}</span>
                      </li>
                    )}
                    {book.pages && (
                      <li>
                        Кількість сторінок:{" "}
                        <span className="context">{book.pages}</span>
                      </li>
                    )}
                    {book.illustrations && (
                      <li>
                        Ілюстрації:{" "}
                        <span className="context">{book.illustrations}</span>
                      </li>
                    )}
                    {(book.width && book.height) && (
                      <li>
                        Формат:{" "}
                        <span className="context">{book.width}x{book.height} мм</span>
                      </li>
                    )}
                    {book.wrapper && (
                      <li>
                        Обкладинка:{" "}
                        <span className="context">{book.wrapper}</span>
                      </li>
                    )}
                    {book.category && (
                      <li>
                        Серія:{" "}
                        <span className="context">{book.category}</span>
                      </li>
                    )}
                  </ul>
                  <div className="price-box">
                    <div className="price">
                      <div>
                        <span className="price-n">{book.price}</span> грн
                      </div>
                    </div>
                    { book.inStock
                      ?
                        <button className="cart" onClick={() => dispatch(addToCart(book))}>
                          <StaticImage src="../images/book_cart.png" alt="book_cart_icon" />
                        </button>
                      :
                        <div className="out-of-stock">немає<br />в наявності</div>
                    }
                  </div>
                </div>
              </div>
            </section>
            <section className="description-section">
              {book.description && (
                <div>
                  <p className="description-title">Опис</p>
                  <p>{book.description}</p>
                </div>
              )}
            </section>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  )
}

export default Book

export const pageQuery = graphql`
  query BookPageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        sku
        title
        author
        publisher
        language
        release
        pages
        illustrations
        width
        height
        wrapper
        category
        description
        price
        inStock
        gallery {
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`